<template>
  <v-app>
    <PageLoader v-bind:storage="appLoading" />
    <v-layout wrap justify-center pt-16>
      <v-flex text-center>
        <v-card flat text-center class="pt-8">
          <span
            style="
              color: black;
              text-align: center;
              font-family: poppinsbold;
              font-size: 25px;
            "
            >Payment Successful
          </span>
          <br />
          <br />
          <v-icon size="50" color="green"> mdi-check-circle </v-icon>
          <br />
          <br />
          <h3
            style="
              text-align: center !important;
              color: black;
              font-family: poppinsmedium;
            "
          >
            Thank You for the Purchasing
          </h3>
          <!-- <p style="text-align:center"><a :href="'https://api.mudumalaitigerreserve.org/booking/ticketpdf/?id='+paymentId" target="_blank"> Please click here to download ticket </a></p> -->
        </v-card>
      </v-flex>
    </v-layout>
    <!-- </v-img> -->
  </v-app>
</template>
<script>
import axios from "axios";
export default {
  name: "Success",
  data() {
    return {
      appLoading: false,
    };
  },
  beforeMount() {
    if (this.$route.query.session_id) {
      this.success();
    }
    console.log("session", this.$route.query.session_id);
  },
  methods: {
    // retpg() {
    //     this.$router.push({ path: "/" });
    // },
    // home: function () {
    //     this.homeload = true;
    //     setTimeout(
    //         function () {
    //             this.homeload = false;
    //             // alert('cool');
    //         }.bind(this),
    //         4000
    //     );
    // },
    success() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/payment/sucess",
        data: {
          id: this.$route.query.session_id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.$store.commit("changeCart", 0);
            setTimeout(
              function () {
                this.$router.push({ path: "/" });
              }.bind(this),
              4000
            );
            //    this.msg = response.data.msg;
            //     this.snackbar = true;
          } else {
            this.$router.push({ path: "/failure" });
            this.msg = response.data.msg;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
  },
};
</script>
<style>
.loadingstyle {
  text-align: center;
  margin: 0px;
  padding: 0px;
  position: fixed;
  right: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 30001;
  opacity: 0.8;
}

.btn.buttonstyle1 {
  border-radius: 25px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 1px !important;
  padding-bottom: 4px !important;
}

@media only screen and (min-width: 1006px) {
  .ht {
    height: auto !important;
  }

  .centered {
    position: absolute;
    top: 40%;
    left: 48%;
  }
}

.centered1 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}

@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .ht {
    height: 1000px !important;
  }

  .centered {
    position: absolute;
    top: 40%;
    left: 48%;
  }

  /* .btn.buttonstyle1 {
      border-radius: 25px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    } */
}
</style>